import { CalendarViewMode } from '@howljs/calendar-kit'
import { CheckboxValueType } from 'antd/es/checkbox/Group'
import { atom, atomFamily } from 'recoil'

import { Customer } from '../apis/types/apiResponseTypes'
import { ScreenViewType } from '../components/screen/types'
import { STORAGE_KEYS } from '../constants/Keys'
import { DataProviderState } from '../dataProvider/types'
import { AnagraficsTab, GanttChartDate, GanttChartView, TabType } from '../types'
import { ActivityTabState } from '../views/activities/ActivityListHeader'
import { PlannerSideViewType } from '../views/planner/PlannerSideView'
import appUtils from './appUtils'

export const lastUsedCustomersAtom = atomFamily<Customer[], string>({
  key: 'lastUsedCustomersAtom',
  default: [],
  effects: param => [appUtils.localStorageEffect(param + STORAGE_KEYS.LAST_USED_CUSTOMERS)],
})

export const selectedCustomerDetailTabAtom = atomFamily<TabType, string>({
  key: 'selectedCustomerDetailTab',
  default: 'contacts',
  effects: param => [appUtils.localStorageEffect(param + STORAGE_KEYS.CUSTOMER_DETAILS_TAB)],
})

export const saveMediaOnStorageAtom = atomFamily<boolean, string>({
  key: 'saveMediaOnStorageAtom',
  default: false,
  effects: param => [appUtils.localStorageEffect(param + STORAGE_KEYS.SAVE_MEDIA_ON_STORAGE)],
})

export const savePlannerDaysStorageAtom = atomFamily<number[], string>({
  key: 'savePlannerDaysStorageAtom',
  default: [1, 2, 3, 4, 5],
  effects: param => [appUtils.localStorageEffect(param + STORAGE_KEYS.SAVE_PLANNING_DAYS_ON_STORAGE)],
})

export const calendarViewAtom = atomFamily<CalendarViewMode, string>({
  key: 'calendarViewAtom',
  default: undefined,
  effects: param => [appUtils.localStorageEffect(param + STORAGE_KEYS.CALENDAR_VIEW)],
})

export const ticketViewAtom = atomFamily<ScreenViewType | undefined, string>({
  key: 'ticketViewAtom',
  default: ScreenViewType.LIST,
  effects: param => [appUtils.localStorageEffect(param + STORAGE_KEYS.TICKET_VIEW)],
})

export const activityTabAtom = atom<ActivityTabState>({
  key: 'activityTabState',
  default: 'my',
  effects: [appUtils.localStorageEffect<ActivityTabState>(STORAGE_KEYS.ACTIVITY_TAB)],
})

export const selectedAnagraficsTabAtom = atom<AnagraficsTab>({
  key: 'anagraficsTabState',
  default: 'ActivityType',
  effects: [appUtils.localStorageEffect<AnagraficsTab>(STORAGE_KEYS.ANAGRAFICS_TAB)],
})

export const plannerActivityFilterCountAtom = atom<number | undefined>({
  key: 'plannerActivityFilterCountAtom',
  default: undefined,
})

export const plannerActivitySearchFilterCountAtom = atom<number | undefined>({
  key: 'plannerActivitySearchFilterCountAtom',
  default: undefined,
})

export const plannerAbsenceFilterCountAtom = atom<number | undefined>({
  key: 'plannerAbsenceFilterCountAtom',
  default: undefined,
})

export const activityHistoryFilterEnableAtom = atom({
  key: 'activityHistoryFilterEnableAtom',
  default: false,
})

export const activityPlanningFilterEnableAtom = atom({
  key: 'activityPlanningFilterEnableAtom',
  default: false,
})

export const landingPageUrlAtom = atom<string | undefined>({
  key: 'landingPageUrlAtom',
  default: undefined,
})

export const revalidateSettingsAtom = atom({
  key: 'revalidateSettingsAtom',
  default: false,
})

export const dataProviderSettingsAtom = atom<DataProviderState>({
  key: 'dataProviderStateAtom',
  default: { isOnline: true },
  effects: [appUtils.localStorageEffect<DataProviderState>(STORAGE_KEYS.DATA_PROVIDER_STATE)],
})

export const ganttChartRefreshAtom = atom({
  key: 'ganttChartRefreshAtom',
  default: false,
})

export const ganttChartDateLabelAtom = atomFamily<GanttChartDate | undefined, string>({
  key: 'ganttChartDateLabelAtom',
  default: undefined,
  effects: param => [appUtils.localStorageEffect<GanttChartDate | undefined>(param + STORAGE_KEYS.GANTT_CHART_DATE_LABEL)],
})

export const ganttChartSelectedTechniciansAtom = atomFamily<CheckboxValueType[] | undefined, string>({
  key: 'ganttChartSelectedTechniciansAtom',
  default: undefined,
  effects: param => [appUtils.localStorageEffect<CheckboxValueType[] | undefined>(param + STORAGE_KEYS.GANTT_CHART_SELECTED_TECHNICIANS)],
})

export const ganttChartViewAtom = atomFamily<GanttChartView, string>({
  key: 'ganttChartViewAtom',
  default: 'week',
  effects: param => [appUtils.localStorageEffect<GanttChartView>(param + STORAGE_KEYS.GANTT_CHART_VIEW)],
})

export const ganttChartClosedPlannerSideViewAtom = atomFamily<boolean, string>({
  key: 'ganttChartClosedPlannerSideViewAtom',
  default: false,
  effects: param => [appUtils.localStorageEffect(param + STORAGE_KEYS.GANTT_CHART_CLOSED_PLANNER_SIDE_VIEW)],
})

export const plannerSideViewTypeAtom = atomFamily<PlannerSideViewType, string>({
  key: 'plannerSideViewTypeAtom',
  default: 'activities',
  effects: param => [appUtils.localStorageEffect(param + STORAGE_KEYS.PLANNER_SIDE_VIEW_TYPE)],
})

export const plannerShowActivityExtendedViewAtom = atomFamily<boolean, string>({
  key: 'plannerShowActivityExtendedViewAtom',
  default: false,
  effects: param => [appUtils.localStorageEffect(param + STORAGE_KEYS.PLANNER_SHOW_ACTIVITY_EXTENDED_VIEW)],
})

export const plannerShowClosedActivitiesAtom = atomFamily<boolean, string>({
  key: 'plannerShowClosedActivitiesAtom',
  default: false,
  effects: param => [appUtils.localStorageEffect(param + STORAGE_KEYS.PLANNER_SHOW_CLOSED_ACTIVITIES)],
})

export const apiVersionAtom = atom({
  key: 'apiVersionAtom',
  default: '',
})
