import { ActivityId } from '../../types'
import { Activity, ActivityState, RdaStatus, TechnicianType } from './apiResponseTypes'

export interface SkipTake {
  skip?: number
  take?: number
}

export interface GetCustomersRequest extends SkipTake {
  id?: string //
  taxId?: string
  email?: string
  vatId?: string
  searchtext?: string
}

export enum ErgoTaskType {
  Service = 'Service',
  Report = 'Report',
}
export type GetErgoTasksRequest = {
  taskType?: ErgoTaskType
}

export interface GetErgoTaskDetail {
  taskId: number
}

export interface GetCustomerDetailRequest {
  customerId: number
}

export interface GetCustomerContactsRequest extends SkipTake {
  customerId: number
  searchtext?: string
}

export interface GetCustomerContactDetailRequest {
  customerId: number
  contactId: number
}

export interface GetCustomerDestinationRequest extends SkipTake {
  customerId: number
  searchtext?: string
}
export interface GetCustomerDestinationDetailRequest {
  customerId: number
  destinationId: number
}

export interface GetCustomerConstructionSitesRequest extends SkipTake {
  customerId: number
  searchtext?: string
}

export interface GetCustomerConstructionSiteDetailRequest {
  customerId: number
  objectId: number
}

export interface GetConstructionSiteRequest extends SkipTake {
  searchtext?: string
}

export interface GetConstructionSiteDetailRequest {
  objectId: number
}

export interface GetConstructionSiteLottosRequest extends SkipTake {
  objectId: number
}
export interface GetConstructionSiteContactsRequest {
  objectId: number
}

export type GetActivityRequest = SkipTake &
  Partial<ActivityId> & {
    state?: ActivityState[]
    assignedUser?: GetActivityRequestAssignedUser
    assignedUserId?: number[]
    srvContractId?: number
    planDateFrom?: string
    planDateTo?: string
    closedDateFrom?: string
    closedDateTo?: string
    activityDateFrom?: string
    activityDateTo?: string
    expireDateFrom?: string
    expireDateTo?: string
    customerId?: number
    objectId?: number
    objectLotId?: number
    searchText?: string
    groupBy?: ActivityGroupBy
    orderBy?: ActivityOrderBy[]
    inElaboration?: ActivityInElaborationFilter
    taskType: ErgoTaskType
    rdaStatus?: RdaStatus[]
  }

export enum ActivityGroupBy {
  ActivityType = 'Type',
  Task = 'TaskId',
  PlanDate = 'PlanDate',
  Customer = 'Customer',
  ConstructionSite = 'ConstructionSite',
}

export enum ActivityOrderBy {
  ActivityDate = 'ActivityDate',
  ActivityDateDesc = 'ActivityDateDesc',
  ClosedDate = 'ClosedDate',
  ClosedDateDesc = 'ClosedDateDesc',
  ExpireDate = 'ExpiredDate',
  ExpireDateDesc = 'ExpiredDateDesc',
  Priority = 'Priority',
  PriorityDesc = 'PriorityDesc',
}

export enum ActivityInElaborationFilter {
  InElaboration = 'InElaboration',
  NotInElaboration = 'NotInElaboration',
}

export interface GetActivityPlanningRequest extends SkipTake {
  state: ActivityState[]
  assignedUser: boolean
  searchText?: string
  companyId: number | undefined
  objectId: number | undefined
}

export enum GetActivityRequestAssignedUser {
  Assigned = 'Assigned',
  Unassigned = 'Unassigned',
  AssignedTo = 'AssignedTo',
  AssignedToPlanner = 'AssignedToPlanner',
  All = 'All',
}

export type GetActivityEmployeeRequest = ActivityId

export type GetActivityEmployeePlanningRequest = {
  planDateFrom: string
  planDateTo: string
  technicianId?: number
  technicianType?: TechnicianType
}

export type GetActivityDetailRequest = ActivityId
export type GetFollowupActivityRequest = ActivityId

export interface GetActivityContractDetailRequest {
  contractId: number
}

export interface GetUnavailableRequest {
  planDateFrom: string
  planDateTo: string
  technicianId?: number
  technicianType?: TechnicianType
}

export interface GetActivityContractLotsRequest {
  contractId: number
  contractLotId?: number
}

export interface GetEmployeeRequest {
  id?: number
  firstName?: string
  lastName?: string
}

export type GetTechniciansRequest = SkipTake & {
  taskId?: number
}

export type GetActivityNotesRequest = ActivityId & SkipTake
export type GetActivityTimeRequest = ActivityId
export type GetActivityTravelRequest = ActivityId
export type GetActivityArticlesRequest = ActivityId

export type GetArticleRequest = {
  searchtext?: string
  returnGoodCategory?: boolean
  validTo?: boolean
  excludeWithFormula?: boolean
  wareHouseId?: number
  returnSupplyArticle?: boolean
  canUseWarehouseFromGoodCategory?: boolean
} & SkipTake

export type GetHistoryArticleRequest = ActivityId & {
  searchtext?: string
} & SkipTake

export type GetSupplierArticleRequest = {
  articleId: string
}

export type GetActivityMedia = ActivityId & {
  SrvActivityNoteId: number
}

export type GetActivityArticleMedia = ActivityId & {
  srvActivityArticleId: number
}

export type GetArticlePriceList = {
  taskId: number
}

export type GetPriceListArticle = {
  priceListId: number
  validTo?: boolean
  goodCategoryId1: number
  goodCategoryId2: number
  goodCategoryId3: number
  goodCategoryId4: number
  returnGoodCategory?: boolean
  wareHouseId?: number
  canUseWarehouseFromGoodCategory?: boolean
}

export interface GetSynchronisation {
  deviceId: string
  type: 'EmService'
  reset?: boolean
}

export interface GetSynchronisationData {
  deviceId: string
  type: SyncResourceType
  blockNumber: number
}

export enum SyncResourceType {
  None = 'None',
  Employees = 'Employees',
  Customers = 'Customers',
  NoteGroups = 'Notegroups',
  QualityChars = 'QualityChars',
  QualityCharArticles = 'QualityCharArticles',
  Tickets = 'Tickets',
  TicketArticles = 'TicketArticles',
  Activities = 'Activties',
}

export type ActivityClosingRequest = {
  srvActivityId: number
  srvActivityTypeId: number
  srvActivityYear: number
  paid?: boolean
  paidAmount?: number
  discount?: number
  isWorking?: boolean
  customerNote?: string
  customerEmail?: string
  sendReport?: boolean
  performedWork?: string
  signatureEmployee?: string
  signatureCustomer?: string
  srvEmailId?: number
  srvEmailAdresses?: string
  srvEmailSettingId?: number
  followUpActivity?: Activity
}

export type ActivityClosingSummaryRequest = ActivityId

export type GetTimeSlotRequest = {
  srvTimeSlotId?: number
}

export type ActivityTypeUpdateOrderRequest = {
  activityTypeId: number[]
}

export type GetEmailSettingsRequest = {
  srvEmailSettingId?: number
}

export type GetEmailModelsRequest = {
  srvEmailId?: number
  srvActivityTypeId?: number
}

export type SendEmailRequest = {
  smtp: SendEmailSmtpSettings
  message: SendEmailBody
}

export type SendEmailSmtpSettings = {
  host: string
  port: number
  password: string
}

export type SendEmailBody = {
  from: string
  to: string
  subject?: string
  body?: string
}
