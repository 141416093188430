import { ActivityGroupBy, ActivityOrderBy, GetActivityRequest } from '../apis/types/apiRequestTypes'
import {
  Activity,
  ConstructionSite,
  ConstructionSiteLotto,
  Contact,
  Contract,
  Destination,
  Employee,
  Technician,
  UnavailableType,
  UserRole,
} from '../apis/types/apiResponseTypes'
import { ApiFilterType, DataFilterConfig, DataFilterConfigType } from '../types'

export const ActivityFilterConfig: Pick<DataFilterConfig<Activity, undefined, GetActivityRequest>, 'apiFilter' | 'group' | 'order'> = {
  group: {
    type: DataFilterConfigType.Group,
    apiRequestKey: 'groupBy',
    config: [
      ['srvActivityTypeId', 'ACTIVITY_TYPE', { textKey: 'activityTypeDescription', apiRequestValue: ActivityGroupBy.ActivityType }],
      ['planTime', 'PLAN_DATE', { textKey: 'planDate', apiRequestValue: ActivityGroupBy.PlanDate }],
      ['taskId', 'TASK', { textKey: 'taskDescription', apiRequestValue: ActivityGroupBy.Task }],
      ['companyId', 'CUSTOMER', { textKey: 'customerDescription', apiRequestValue: ActivityGroupBy.Customer }],
      ['objectId', 'CONSTRUCTION_SITE', { textKey: 'constructionSiteDescription', apiRequestValue: ActivityGroupBy.ConstructionSite }],
    ],
  },
  order: {
    type: DataFilterConfigType.Order,
    apiRequestKey: 'orderBy',
    config: [
      ['activityDate', 'CREATION_DATE', { apiRequestValue: ActivityOrderBy.ActivityDate, isDefault: true }],
      ['activityDate', 'CREATION_DATE', { apiRequestValue: ActivityOrderBy.ActivityDateDesc, modifier: 'inverse' }],
      ['expireDate', 'EXPIRE_DATE', { apiRequestValue: ActivityOrderBy.ExpireDate }],
      ['expireDate', 'EXPIRE_DATE', { modifier: 'inverse', apiRequestValue: ActivityOrderBy.ExpireDateDesc }],
      ['priority', 'PRIORITY', { modifier: 'inverse', apiRequestValue: ActivityOrderBy.PriorityDesc }],
    ],
  },
  apiFilter: {
    type: DataFilterConfigType.ApiFilter,
    config: [
      ['srvActivityTypeId', 'ACTIVITY_TYPE', { filterType: ApiFilterType.ActivityType }],
      ['assignedUserId', 'EMPLOYEE', { filterType: ApiFilterType.Technician, excludeRoles: [UserRole.technician] }],
      ['customerId', 'CUSTOMER', { filterType: ApiFilterType.Customer }],
      ['objectId', 'CONSTRUCTION_SITE', { filterType: ApiFilterType.ConstructionSite }],
      ['planDateFrom', 'PLAN_DATE_FROM', { filterType: ApiFilterType.Date, group: 'planDate', textPrefix: '> ', icon: ['fal', 'calendar-clock'] }],
      ['planDateTo', 'DATE_TO', { filterType: ApiFilterType.Date, group: 'planDate', textPrefix: '< ', icon: ['fal', 'calendar-clock'] }],
      ['activityDateFrom', 'ACTIVITY_DATE_FROM', { filterType: ApiFilterType.Date, group: 'activityDate', textPrefix: '> ' }],
      ['activityDateTo', 'DATE_TO', { filterType: ApiFilterType.Date, group: 'activityDate', textPrefix: '< ' }],
      [
        'expireDateFrom',
        'EXPIRE_DATE_FROM',
        { filterType: ApiFilterType.Date, group: 'expireData', textPrefix: '> ', icon: ['fal', 'hourglass-clock'] },
      ],
      ['expireDateTo', 'DATE_TO', { filterType: ApiFilterType.Date, group: 'expireData', textPrefix: '< ', icon: ['fal', 'hourglass-clock'] }],
      ['inElaboration', 'STATE', { filterType: ApiFilterType.InElaboration }],
      ['rdaStatus', 'STATE', { filterType: ApiFilterType.RdaStatus }],
    ],
  },
}

export const ContactFilterConfig: Pick<DataFilterConfig<Contact>, 'searchKeys'> = {
  searchKeys: ['description', 'firstName', 'lastName', 'email'],
}

export const DestinationFilterConfig: Pick<DataFilterConfig<Destination>, 'searchKeys'> = {
  searchKeys: ['description', 'email'],
}

export const ConstructionSiteFilterConfig: Pick<DataFilterConfig<ConstructionSite>, 'searchKeys'> = {
  searchKeys: ['description', 'email'],
}

export const ConstructionSiteLottoFilterConfig: Pick<DataFilterConfig<ConstructionSiteLotto>, 'searchKeys'> = {
  searchKeys: ['description'],
}

export const ActivityContractFilterConfig: Pick<DataFilterConfig<Contract>, 'searchKeys'> = {
  searchKeys: ['titleDe', 'titleEn', 'titleIt', 'noteDe', 'noteEn', 'noteIt'],
}

export const PlanningActivityFilterConfig: Pick<DataFilterConfig<Activity, Employee>, 'filter'> = {
  filter: {
    type: DataFilterConfigType.Filter,
    config: [
      ['srvContractId', 'CONTRACT', { filterType: 'boolean', trueText: 'WITH_CONTRACT', falseText: 'WITHOUT_CONTRACT' }],
      ['activityTypeDescription', 'ACTIVITY_TYPE'],
      [
        'assignedUser',
        'EMPLOYEE',
        { filterType: 'array', valueExtractor: employee => (employee.firstName ?? '') + (employee.lastName ? ' ' + employee.lastName : '') },
      ],
      ['expireDate', 'EXPIRE_DATE', { filterType: 'dateRange' }], // Keep this as last element
    ],
  },
}

export const PlanningAbsenceFilterConfig: Pick<DataFilterConfig<UnavailableType>, 'filter'> = {
  filter: {
    type: DataFilterConfigType.Filter,
    config: [['type', 'UNAVAILABLE_TYPES']],
  },
}

export const HistoryFilterConfig: Pick<DataFilterConfig<Activity, undefined, GetActivityRequest>, 'apiFilter' | 'group'> = {
  group: {
    type: DataFilterConfigType.Group,
    apiRequestKey: 'groupBy',
    config: [
      ['srvActivityTypeId', 'ACTIVITY_TYPE', { textKey: 'activityTypeDescription', apiRequestValue: ActivityGroupBy.ActivityType }],
      ['taskId', 'TASK', { textKey: 'taskDescription', apiRequestValue: ActivityGroupBy.Task }],
      ['companyId', 'CUSTOMER', { textKey: 'customerDescription', apiRequestValue: ActivityGroupBy.Customer }],
      ['objectId', 'CONSTRUCTION_SITE', { textKey: 'constructionSiteDescription', apiRequestValue: ActivityGroupBy.ConstructionSite }],
    ],
  },
  apiFilter: {
    type: DataFilterConfigType.ApiFilter,
    config: [
      ['srvActivityTypeId', 'ACTIVITY_TYPE', { filterType: ApiFilterType.ActivityType }],
      ['closedDateFrom', 'CLOSED_DATE_FROM', { filterType: ApiFilterType.Date, group: 'closedDate', textPrefix: '> ' }],
      ['closedDateTo', 'DATE_TO', { filterType: ApiFilterType.Date, group: 'closedDate', textPrefix: '< ' }],
      ['customerId', 'CUSTOMER', { filterType: ApiFilterType.Customer }],
      ['objectId', 'CONSTRUCTION_SITE', { filterType: ApiFilterType.ConstructionSite }],
      ['objectLotId', 'CONSTRUCTION_SITE_LOTTO', { filterType: ApiFilterType.Lotto }],
      ['assignedUserId', 'EMPLOYEE', { filterType: ApiFilterType.TechnicianFull, excludeRoles: [UserRole.technician] }],
      ['rdaStatus', 'STATE', { filterType: ApiFilterType.RdaStatus }],
    ],
  },
}

export const TechniciansFilterConfig: Pick<DataFilterConfig<Technician>, 'searchKeys'> = {
  searchKeys: ['firstName', 'lastName'],
}

export const ReportActivityFilterConfig: Pick<DataFilterConfig<Activity, undefined, GetActivityRequest>, 'apiFilter' | 'order'> = {
  order: {
    type: DataFilterConfigType.Order,
    apiRequestKey: 'orderBy',
    config: [
      ['closedDate', 'CLOSED_DATE', { apiRequestValue: ActivityOrderBy.ClosedDate, isDefault: true }],
      ['closedDate', 'CLOSED_DATE', { apiRequestValue: ActivityOrderBy.ClosedDateDesc, modifier: 'inverse' }],
    ],
  },
  apiFilter: {
    type: DataFilterConfigType.ApiFilter,
    config: [
      ['srvActivityTypeId', 'ACTIVITY_TYPE', { filterType: ApiFilterType.ActivityType }],
      ['customerId', 'CUSTOMER', { filterType: ApiFilterType.Customer }],
      ['objectId', 'CONSTRUCTION_SITE', { filterType: ApiFilterType.ConstructionSite }],
      ['objectLotId', 'CONSTRUCTION_SITE_LOTTO', { filterType: ApiFilterType.Lotto }],
      ['assignedUserId', 'EMPLOYEE', { filterType: ApiFilterType.TechnicianFull, excludeRoles: [UserRole.technician] }],
      ['closedDateFrom', 'CLOSED_DATE_FROM', { filterType: ApiFilterType.Date, group: 'closedDate', textPrefix: '> ' }],
      ['closedDateTo', 'DATE_TO', { filterType: ApiFilterType.Date, group: 'closedDate', textPrefix: '< ' }],
    ],
  },
}
