import { IM, IMStyle, useLanguage } from '@infominds/react-native-components'
import { DatePicker } from 'antd'
import { DatePickerProps } from 'antd/lib'
import dayjs from 'dayjs'
import React, { forwardRef, useMemo } from 'react'
import { StyleSheet } from 'react-native'

import ScrollViewData from '../../components/ScrollViewData'
import SorterTag from '../../components/SorterTag'
import Tag from '../../components/Tag'
import useExtendedTheme from '../../hooks/useExtendedTheme'
import useFilter from '../../hooks/useFilter'
import { DataFilterConfigType } from '../../types'
import ApiFilterView from './ApiFilterView'
import FilterOrderView from './FilterOrderView'

function FilterView() {
  const { filters, apiFilter, groups, orders, changeFilterStatus, changeFilterDateRange } = useFilter()
  const { i18n, language } = useLanguage()
  const { theme, colorScheme } = useExtendedTheme()

  const customFormat: DatePickerProps['format'] = value => value.set('h', 0).set('m', 0).set('s', 0).set('ms', 0).locale(language).format('L')

  const datesMaxMin = useMemo(() => {
    return filters
      .map((filter, index) => {
        const minDate = filter.values.find(el => el.isDateRangeMax === false)?.value
        const maxDate = filter.values.find(el => el.isDateRangeMax === true)?.value

        if (filter.options?.filterType === 'dateRange' && !!minDate && !!maxDate) {
          return { index, minDate, maxDate }
        }

        return undefined
      })
      .filter(q => q)
  }, [filters])

  const noFilter = (
    <IM.Text secondary style={{ fontSize: IMStyle.typography.fontSizeSmall - 1 }}>
      {i18n.t('NO_ACTIVITY_FILTER')}
    </IM.Text>
  )

  return (
    <ScrollViewData>
      {groups.length > 0 && (
        <IM.View>
          <IM.View style={styles.titleContainer}>
            <IM.Icon icon={['fal', 'layer-group']} spacing="right" />
            <IM.Text style={styles.title}>{i18n.t('GROUP_BY')}</IM.Text>
          </IM.View>
          <IM.View style={styles.groupContainer}>
            {groups.map(group => {
              return <SorterTag key={group.id} sorter={group} style={styles.tag} onPress={id => changeFilterStatus(DataFilterConfigType.Group, id)} />
            })}
          </IM.View>
        </IM.View>
      )}
      <IM.View spacing={groups.length === 0 ? 'none' : 'top'}>
        <IM.View style={styles.titleContainer}>
          <IM.Icon icon={['fal', 'filter']} spacing="right" size={15} />
          <IM.Text style={styles.title}>{i18n.t('FILTERS')}</IM.Text>
        </IM.View>
      </IM.View>
      <IM.View spacing="top">
        {filters.length === 0 && apiFilter.length === 0 && noFilter}
        {filters.map((filter, index) => {
          const maxMin = datesMaxMin.find(el => el?.index === index)

          return (
            <IM.View key={filter.id}>
              <IM.Text style={{ fontSize: IMStyle.typography.fontSizeSmall - 1 }}>{i18n.t(filter.textKey)}</IM.Text>
              <IM.View style={styles.filterContainer}>
                {filter.values.length === 0 ? (
                  <>{noFilter}</>
                ) : (
                  <>
                    {filter.options?.filterType === 'dateRange' ? (
                      <DatePicker.RangePicker
                        defaultValue={maxMin ? [dayjs(maxMin.minDate), dayjs(maxMin.maxDate)] : undefined}
                        format={customFormat}
                        getPopupContainer={triggerNode => triggerNode.parentNode as HTMLElement}
                        onChange={val => {
                          const start = val && val[0] ? val[0].toISOString() : undefined
                          const end = val && val[1] ? val[1].toISOString() : undefined

                          changeFilterDateRange(filter.id, start, end)
                        }}
                        // eslint-disable-next-line react-native/no-inline-styles
                        style={{
                          backgroundColor: theme.filterTag.default.background,
                          borderColor: colorScheme === 'light' ? undefined : 'transparent',
                        }}
                      />
                    ) : (
                      <>
                        {filter.values.map(el => {
                          return (
                            <Tag
                              key={el.id}
                              id={el.id}
                              name={`${el.value}${el.count ? ` (${el.count})` : ''}`}
                              active={!!el.active}
                              style={styles.tag}
                              onPress={id => changeFilterStatus(DataFilterConfigType.Filter, id)}
                            />
                          )
                        })}
                      </>
                    )}
                  </>
                )}
              </IM.View>
            </IM.View>
          )
        })}
        <ApiFilterView />
      </IM.View>
      {orders.length > 0 && <FilterOrderView />}
    </ScrollViewData>
  )
}

const styles = StyleSheet.create({
  title: {
    fontWeight: IMStyle.typography.fontWeightMedium,
  },
  titleContainer: {
    alignItems: 'center',
    flexDirection: 'row',
  },
  filterContainer: {
    flexWrap: 'wrap',
    flexDirection: 'row',
    marginTop: 2,
    marginBottom: 10,
  },
  groupContainer: {
    flexWrap: 'wrap',
    flexDirection: 'row',
    marginTop: 6,
    marginBottom: 14,
  },
  tag: { marginVertical: 3 },
})

export default forwardRef(FilterView)
