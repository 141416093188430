import { IM, IMLayout, IMStyle, useLanguage, Utils, ViewProps } from '@infominds/react-native-components'
import React, { useEffect, useMemo } from 'react'
import { Platform, StyleProp, StyleSheet, ViewStyle } from 'react-native'

import { ActivityClosingSummary } from '../../../apis/types/apiResponseTypes'
import { BaseNumberInput, BaseNumberInputProps } from '../../../components/BaseNumberInput'
import IMContentCard from '../../../components/card/IMContentCard'
import SplitText from '../../../components/SplitText'
import { AppStyle } from '../../../constants/Styles'
import useExtendedTheme from '../../../hooks/useExtendedTheme'
import { ActivityClosingState, LoadingType } from '../../../types'
import appUtils from '../../../utils/appUtils'
import ClosingSummaryView from '../../../views/activities/closing/ClosingSummaryView'

export type ActivityClosingCardProps = {
  state: Partial<ActivityClosingState>
  setState: React.Dispatch<React.SetStateAction<Partial<ActivityClosingState>>>
  summary: ActivityClosingSummary | undefined | null
  loading: LoadingType
  mode?: 'full' | 'total-only' | 'hide-prices'
}

export default function ActivityClosingCard({ state, setState, summary, loading, mode = 'full' }: ActivityClosingCardProps) {
  const { i18n } = useLanguage()
  const { theme } = useExtendedTheme()

  const total = useMemo(() => summary?.totalAmountWithVat ?? 0, [summary?.totalAmountWithVat])
  const totalText = useMemo(() => (loading ? '' : appUtils.formatPrice(total ?? 0)), [total, loading])
  const vatText = useMemo(() => (loading ? '' : appUtils.formatPrice(summary?.totalVatAmount ?? 0)), [summary, loading])

  useEffect(() => {
    if (total) handleTotalAmountChanged(total)
  }, [total])

  function handleDataChanged(newState: Partial<ActivityClosingState>) {
    setState(prev => ({ ...prev, ...newState }))
  }

  function handleDiscountChanged(newDiscount: number) {
    handleDataChanged({ discount: newDiscount, totalAmount: Utils.roundToPrecision(Math.max(total - Math.max(newDiscount ?? 0, 0), 0), 2) })
  }

  function handleTotalAmountChanged(newTotal: number) {
    handleDataChanged({ totalAmount: newTotal, discount: Utils.roundToPrecision(Math.max(total - Math.max(newTotal ?? 0, 0), 0), 2) })
  }

  const dividerStyle = useMemo<StyleProp<ViewStyle>>(() => ({ borderTopWidth: 1, borderTopColor: theme.closingSummary.divider }), [theme])
  return (
    <IMContentCard header={<SplitText leftText={i18n.t('CATEGORY')} rightText={i18n.t('TOTAL')} style={styles.headerText} spacing={'all'} />}>
      <ClosingSummaryView loading={loading} summary={summary} mode={mode} style={styles.contentView} />
      {mode !== 'hide-prices' && (
        <>
          <IM.View spacing={'all'} style={[dividerStyle]}>
            <SplitText leftText={`${i18n.t('TOTAL')} (${i18n.t('VAT_INCL')})`} rightText={totalText} spacing={['horizontal']} primary />
            <SplitText leftText={i18n.t('VAT_SUMMARY')} rightText={vatText} spacing={['horizontal']} secondary />
          </IM.View>

          <Input
            title={i18n.t('DISCOUNT')}
            spacing={'left'}
            style={[dividerStyle]}
            value={state.discount}
            onChangeValue={handleDiscountChanged}
            maxValue={total}
            editable={!loading}
          />
          <Input
            title={i18n.t('TOTAL_TO_PAY')}
            spacing={'left'}
            style={[dividerStyle]}
            isLast
            primary
            value={state.totalAmount}
            onChangeValue={handleTotalAmountChanged}
            maxValue={total}
            editable={!loading}
          />
        </>
      )}
    </IMContentCard>
  )
}

function Input({
  isLast,
  title,
  value,
  onChangeValue,
  primary,
  maxValue,
  editable,
  ...viewProps
}: ViewProps & { isLast?: boolean; title: string; primary?: boolean; maxValue?: number } & Pick<
    BaseNumberInputProps,
    'value' | 'onChangeValue' | 'editable'
  >) {
  const { theme } = useExtendedTheme()

  return (
    <IM.View {...viewProps}>
      <IM.View style={[IMLayout.flex.row, styles.splitView]}>
        <IM.View style={[IMLayout.flex.f1, AppStyle.justifyContentCenter, styles.inputTextView]} spacing={['left']}>
          <IM.Text primary={primary} style={[]}>
            {title}
          </IM.Text>
        </IM.View>
        <BaseNumberInput
          value={value}
          onChangeValue={v => onChangeValue(Utils.roundToPrecision(v, 2))}
          style={[Platform.OS === 'web' ? styles.inputWeb : styles.input]}
          selectTextOnFocus
          minValue={0}
          maxValue={maxValue}
          editable={editable}
          internalValueFormatter={v => appUtils.formatPrice(Utils.roundToPrecision(v, 2), null)}
          containerStyle={[
            styles.inputContainer,
            { backgroundColor: theme.closingSummary.input.background },
            isLast && {
              borderBottomRightRadius: IMLayout.borderRadius,
            },
          ]}
          unit={
            <IM.View style={[AppStyle.center]}>
              <IM.Text>€</IM.Text>
            </IM.View>
          }
        />
      </IM.View>
    </IM.View>
  )
}

const styles = StyleSheet.create({
  contentView: {
    minHeight: 125, // empirical value to provide nice loading -> data overlap
  },
  splitView: {
    justifyContent: 'space-between',
  },
  headerText: {
    color: IMStyle.palette.white,
  },
  inputContainer: {
    width: '35%',
    paddingLeft: IMLayout.horizontalMargin,
    paddingRight: IMLayout.horizontalMargin * 2,
  },
  input: {
    textAlign: 'right',
    flexGrow: 1,
    backgroundColor: 'transparent',
    paddingRight: 5,
  },
  inputWeb: {
    textAlign: 'right',
    width: '100%',
    paddingRight: 5,
    backgroundColor: 'transparent',
  },
  inputTextView: {
    paddingVertical: IMLayout.verticalMargin * 2,
  },
})
